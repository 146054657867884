<template>
  <div class="hello">
    <NavigationCommon activeIndex="1"></NavigationCommon>
    <div class="content">
      <div class="content-left">
        <!--公益项目-->
        <div class="gongyixinagmu">
          <el-card>
            <template #header>
              <div class="card-header">
                <span class="cardTitle">公益项目</span>
              </div>
            </template>
            <el-card class="card" v-for="item in charityProjects" :key="item.id">
              <template #footer>
                <a :href="item.linkUrl" target="_blank">
                  <p class="title">{{ item.title }}</p>
                  <p class="title">{{ item.publishDate }}</p>
                </a>
              </template>
              <img :src="item.mainImgUrl" style="width: 100%;" />
            </el-card>
          </el-card>
        </div>
        <!--机构动态-->
        <div class="jigoudongtai">
          <el-card>
            <template #header>
              <div class="card-header">
                <span class="cardTitle">机构动态</span>
              </div>
            </template>
            <div class="dongtaiRow" v-for="item in organStatus" :key="item.id">
              <div class="dongtaiImg">
                <img class="image" :src="item.mainImgUrl" />
              </div>
              <div class="dongtaiTitle">
                <a :href="item.linkUrl" target="_blank">
                  <p><span class="titleContent">{{ item.title }}</span></p>
                  <p><span class="titleDate">{{ item.publishDate }}</span></p>
                </a>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div class="content-right">
        <!--行业动态-->
        <el-card>
          <template #header>
            <div class="card-header">
                <span class="cardTitle">行业资讯</span>
              </div>
          </template>
          <el-card v-for="item in industryNews" :key="item.id">
            <template #footer>
                <a :href="item.linkUrl" target="_blank">
                  <p class="title">{{ item.title }}</p>
                  <p class="title">{{ item.publishDate }}</p>
                </a>
              </template>
              <img :src="item.mainImgUrl" style="width: 100%;" />
          </el-card>
        </el-card>
        <!--信息披露-->
        <el-card>
          <template #header>
            <div class="card-header">
                <span class="cardTitle">信息披露</span>
            </div>
          </template>
          <ul class="ul">
            <li class="li" v-for="item in informationShow" :key="item.id"><a :href="item.linkUrl">{{ item.title }}</a></li>
          </ul>
        </el-card>
      </div>
    </div>
    <FooterCommon class="footer" activeIndex="1"></FooterCommon>
  </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { indexData } from '@/api/cmsApi';
export default {
  name: 'IndexPage',
  components: {
    NavigationCommon,
    FooterCommon
  },
  props: {
    msg: String
  },
  data() {
    return {
      charityProjects: [],
      organStatus: [],
      industryNews: [],
      informationShow: []
    }
  },
  methods: {
    init() {
      indexData().then(res => {
        if (res.code === 200) {
          let data = res.data
          this.charityProjects = data.charityProjects
          this.organStatus = data.organStatus
          this.industryNews = data.industryNews
          this.informationShow = data.informationShow
        }
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.content {
  max-width: 1140px;
  margin: 20px auto;
  display: flex;
}
.content-left {
  max-height: 1735px;
  width: 69%;
}
.content-right {
  max-height: 1600px;
  width: 30%;
}
.gongyixiangmu {
  margin: 10px 1px;
  width: 100%;
}
.jigoudongtai {
  margin: 10px 1px;
  width: 100%;
}
.card {
  display: inline-block;
  margin: 10px 5px;
  width: 45%;
  height: 470px;
}
.title {
  line-height: 16px;
  float:left;
}
.cardTitle {
  font-weight: bold;
  font-size: xx-large;
  color: red;
}
.dongtaiRow {
  height: 240px;
  display: flex;
  margin-top: 20px;
}
.dongtaiImg {
  width: 32%;
}
.dongtaiTitle {
  width: 60%;
  font-size: x-large;
  text-align: left;
  margin-left: 30px;
  margin-top: 50px;
}
.titleContent {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.titleDate {
  font-size: 16px;
}
a {
  text-decoration: none;
}
.ul {
  text-align: left;
}
.li {
  line-height: 36px;
}
.image {
  width: 200px;
}
.footer{
  margin-top: 35px;
}
</style>
