<template>
    <div>
        <NavigationCommon activeIndex="5"></NavigationCommon>
        <div class="content">
            <div class="content-left">
                <el-menu :default-active="currentIndex" @Select="menuSelect" >
                    <template v-for="(item, index) in leftMenus" v-bind:key="item.id">
                        <el-menu-item v-if="!item.children || item.children.length == 0" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                        <el-sub-menu v-else :index="index">
                            <template #title>{{ item.name }}</template>
                            <el-menu-item v-for="(subItem) in item.children" v-bind:key="subItem.id" :index="subItem.code">
                                {{ subItem.name }}
                            </el-menu-item>
                        </el-sub-menu>
                    </template>
                </el-menu>
            </div>
            <el-card class="content-right">
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">{{ title }}</span>
                    </div>
                </template>
                <div>
                    <div class="dongtaiRow" v-for="item in tableData" :key="item.id">
                        <div class="dongtaiImg">
                            <img :src="item.mainImgUrl" class="dongtaiImgTab"/>
                        </div>
                        <div class="dongtaiTitle">
                            <a v-if="item.content" :href="'/detail?id=' + item.id " target="_blank">
                                <p><span class="titleContent">{{ item.title }}</span></p>
                                <p><span class="titleDate">{{ item.publishDate }}</span></p>
                            </a>
                            <a v-if="!item.content" :href="item.linkUrl" target="_blank">
                                <p><span class="titleContent">{{ item.title }}</span></p>
                                <p><span class="titleDate">{{ item.publishDate }}</span></p>
                            </a>
                        </div>
                    </div>
                    <div class="page">
                        <el-pagination background layout="prev, pager, next" class="pageContent" @current-change="handleCurrentChange" :page-size="pageSize" :total="total" :current-page="pageNo"/>
                    </div>
                </div>
                
            </el-card>
        </div>
        <FooterCommon activeIndex="5"></FooterCommon>
    </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { informationShow, listInformationMenus } from '@/api/cmsApi';
export default {
    name: 'AboutUs',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            tableData: [],
            pageNo: 1,
            pageSize: 5,
            total: 0,
            currentIndex: '',
            leftMenus: [],
            title: ''
        }
    },
    methods: {
        async init() {
            await this.queryLeftMenu()
            await this.menuSelect(this.currentIndex)
        },
        async query() {
            let param = {'pageNo': this.pageNo, 'pageSize': this.pageSize, 'type': this.currentIndex}
            await informationShow(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.records
                    this.total = res.data.total
                }
            })
        },
        async queryLeftMenu() {
            await listInformationMenus().then(res => {
                if (res.code === 200) {
                    this.leftMenus = res.data
                    if (!this.title && this.leftMenus) {
                        this.currentIndex = this.leftMenus[0].code
                        this.title = this.leftMenus[0].name
                        this.currentIndex = this.leftMenus[0].code
                    }
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        },
        async menuSelect(index) {
            for (var item in this.leftMenus) {
                if (index === this.leftMenus[item].code) {
                    this.title = this.leftMenus[item].name
                } else if (this.leftMenus[item].children) {
                    for (var subItem in this.leftMenus[item].children) {
                        if (index === this.leftMenus[item].children[subItem].code) {
                            this.title = this.leftMenus[item].children[subItem].name
                        }
                    }
                }
            }
            this.currentIndex = index
            this.pageNo = 1
            this.query()
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    .title {
        margin-top: 20px;
        font-size: xx-large;
        font-weight: bold;
        color: red;
        text-align: center;
    }
    .cardTitle {
        font-weight: bold;
        font-size: x-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
        margin-top: 20px;
        min-height: 800px;
        height: 1500px;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .dongtaiRow {
        height: 240px;
        display: flex;
        margin-top: 20px;
    }
    .dongtaiImg {
        width: 32%;
    }
    .dongtaiImgTab {
        width: 245px;
        height: 170px;
    }
    .dongtaiTitle {
        width: 60%;
        font-size: x-large;
        text-align: left;
        margin-left: 30px;
        margin-top: 50px;
    }
    .titleContent {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleDate {
        font-size: 16px;
    }
    a {
        text-decoration: none;
    }
    .content-left {
        height: 100%;
        width: 20%;
        float: left;
    }
    .content-right {
        height: 100%;
        width: 79%;
        float: right;
    }
</style>